import "flag-icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import $locales from "@/core/locales/store";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

const Locales = () => {
  const navigate = useNavigate();

  const goToCreateForm = () => {
    navigate("/add-locale");
  };

  return (
    <Card sx={{ m: 2 }}>
      <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ ml: 1, mr: 3 }} variant="h6" component="div">
            Языки
          </Typography>
        </Box>
        <Box sx={{ pl: 4 }}>
          <Button onClick={goToCreateForm}>Добавить</Button>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        {$locales.getData.map((locale) => (
          <Chip
            variant="outlined"
            key={`locale-${locale.id}`}
            sx={{ mr: 1, mb: 1 }}
            size="small"
            label={locale.name}
          />
        ))}
      </Box>
    </Card>
  );
};

export default observer(Locales);
