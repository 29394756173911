import { flow, types } from "mobx-state-tree";
import { fetchData } from "@/core/api/projects";
import $translations from "@/core/translations/store";

const $projects = types
  .model("$projects", {
    data: types.array(
      types.model("$projects.item", {
        id: types.string,
        name: types.string,
        locales: types.array(
          types.model("$projects.item.locale", {
            id: types.string,
            name: types.string,
          })
        ),
      })
    ),
    active: types.maybe(types.string),
  })
  .actions((self) => ({
    fetchData: flow(function* () {
      const { data } = yield fetchData();
      self.setData(data);
    }),
    setData(data) {
      self.data = data;
    },
    setActive(id) {
      self.active = id;
      $translations.destroy();
    },
  }))
  .views((self) => ({
    get getData() {
      return self.data;
    },
    get getActive() {
      return self.data.find((d) => d.id === self.active);
    },
    get getLocales() {
      return self.getActive?.locales;
    },
  }))
  .create();

export default $projects;
