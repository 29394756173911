import { flow, types } from "mobx-state-tree";
import { fetchData, saveData } from "@/core/api/locales";

const $locales = types
  .model("$locales", {
    data: types.array(
      types.model("$locales.item", {
        primary: types.maybe(types.boolean),
        id: types.string,
        name: types.string,
      })
    ),
  })
  .actions((self) => ({
    fetchData: flow(function* () {
      const { data } = yield fetchData();
      self.setData(data);
    }),
    save: flow(function* (body) {
      const { data } = yield saveData(body);
      self.setData(data);
    }),
    setData(data) {
      self.data = data;
    },
  }))
  .views((self) => ({
    get getData() {
      return self.data;
    },
    getById(id) {
      return self.getData.find((l) => l.id === id);
    },
  }))
  .create();

export default $locales;
