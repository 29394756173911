import React from "react";
import { translate } from "@/core/api/translate";
import { styled } from "@mui/material";

const Wrapper = styled("div")``;

const Translate = (props) => {
  const [value, setValue] = React.useState();
  React.useEffect(() => {
    (async () => {
      try {
        const { status, data } = await translate({
          text: props.from,
          from: "ru",
          to: props.to,
          lite: true,
        });
        if (status === false) throw { data };
        props.onClick(data.translations[0].text);
      } catch (e) {
        console.warn(e);
      }
    })();
  }, []);

  return null;

  // return (
  //   <div>
  //     <Chip
  //       label={value}
  //       size="small"
  //       onClick={() => props.onClick(value)}
  //       icon={<GTranslate />}
  //     />
  //   </div>
  // )
};

export default Translate;
