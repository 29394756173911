import { GET, POST, PUT } from "./index";

export function fetchAllLocales() {
  return GET("/all-locales");
}

export function fetchData() {
  return GET("/locales");
}

export function saveData(body) {
  return POST("/locales", body);
}

export function editData(body) {
  return PUT("/locales", body);
}
