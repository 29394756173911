import React from "react";
import { observer } from "mobx-react";
import { Route, Routes } from "react-router-dom";
import $projects from "@/core/projects/store";
import $translations from "@/core/translations/store";
import $locales from "@/core/locales/store";
import Box from "@mui/material/Box";
import Header from "@/components/Header";
import Projects from "@/components/Projects";
import TranslateEditor from "@/components/TranslateEditor";
import CreateTranslateMaster from "@/components/CreateTranslateMaster";
import Locales from "@/components/Locales";
import LocalePage from "@/components/Locales/Page";
import LocaleForm from "@/components/Locales/Form";

function App() {
  React.useEffect(() => {
    $locales.fetchData();
    $projects.fetchData();
  }, []);

  React.useEffect(() => {
    $translations.fetchData();
  }, [$projects.getActive]);

  // const beforeUnloadListener = (event) => {
  //   event.preventDefault();
  //   event.returnValue = "";
  //   return true;
  // };

  // React.useEffect(() => {
  //   window.addEventListener("beforeunload", beforeUnloadListener);
  //   return () => {
  //     window.removeEventListener("beforeunload", beforeUnloadListener);
  //   };
  // }, []);

  // if ($projects.getData.length === 0) {
  //   return null;
  // }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Header />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <Routes>
          <Route path="/master" element={<CreateTranslateMaster />} />
          <Route path="/project/:projectId/*" element={<TranslateEditor />} />
          <Route path="/locales" element={<LocalePage />} />
          <Route
            path="/add-locale"
            element={
              <Box sx={{ maxWidth: 800 }}>
                <LocaleForm />
              </Box>
            }
          />
          <Route
            path="/"
            exact
            element={
              <Box sx={{ maxWidth: 800 }}>
                <Locales />
                <Projects />
              </Box>
            }
          />
        </Routes>
      </Box>
    </Box>
  );
}

export default observer(App);
