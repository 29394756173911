export const languages = {
  ru: {
    name: "русский",
    flag: "ru",
    code: "ru",
  },
  en: {
    name: "английский",
    flag: "us",
    code: "en",
  },
  ua: {
    name: "украинский",
    flag: "ua",
    code: "uk",
  },
  az: {
    name: "азербайджанский",
    flag: "az",
    code: "az",
  },
  hy: {
    name: "армянский",
    flag: "am",
    code: "hy",
  },
  kk: {
    name: "казахский",
    flag: "kz",
    code: "kk",
  },
};
