import grey from "@mui/material/colors/grey";

const theme = (mode) => ({
  palette: {
    mode,
    primary: {
      main: "#2185D0",
    },
    secondary: {
      main: grey["200"],
    },
  },
  typography: {
    button: {},
  },
  components: {
    MuiCssBaseline: {},
  },
});

export default theme;
