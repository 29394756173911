import * as React from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import $projects from "@/core/projects/store";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

const Item = ({ item, onClick }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <CardActionArea
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
        onClick={onClick}
      >
        <CardContent sx={{ display: "flex" }}>
          <Avatar sx={{ textTransform: "uppercase" }}>{item.name[0]}</Avatar>
          <Box sx={{ pl: 2 }}>
            <Typography variant="h6" component="div">
              {item.name}
            </Typography>
            <Typography sx={{ fontSize: 12 }} color="text.secondary">
              {item.id}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Box>
  );
};

const Projects = () => {
  const navigate = useNavigate();
  const setActive = (project) => {
    navigate(`/project/${project.id}`);
  };

  React.useEffect(() => {
    $projects.setActive(undefined);
  }, []);

  return (
    <Card
      sx={{
        m: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ ml: 1, mr: 3 }} variant="h6" component="div">
            Проекты
          </Typography>
        </Box>
        <Box sx={{ pl: 4 }}>
          <Button disabled>Добавить</Button>
        </Box>
      </Box>
      {$projects.getData.map((item, index) => (
        <React.Fragment key={`project-${item.id}`}>
          <Divider />
          <Item onClick={() => setActive(item)} item={item} />
        </React.Fragment>
      ))}
    </Card>
  );
};

export default observer(Projects);
