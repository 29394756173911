import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { observer } from "mobx-react";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import App from "@/App";
import theme from "@/theme";
import Snackbar from "@/components/Snackbar";
import $theme from "@/core/ui/theme";
import Auth from "@/components/AuthForm";
import $user from "@/core/user/store";
import GlobalStyles from "@/theme/GlobalStyles";

const container = document.getElementById("root");
const root = createRoot(container);

const ThemeWrapper = observer(() => {
  const myTheme = createTheme(theme($theme.mode));

  React.useEffect(() => {
    $user.profile();
  }, []);

  return (
    <ThemeProvider theme={myTheme}>
      <GlobalStyles mode={$theme.mode} />
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        {$user.isAuth ? <App /> : <Auth />}
        <Snackbar />
      </StyledEngineProvider>
    </ThemeProvider>
  );
});

root.render(
  <BrowserRouter>
    <ThemeWrapper />
  </BrowserRouter>
);
