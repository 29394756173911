import React from "react";
import { languages } from "@/core/translations/domain";
import { createTranslation } from "@/core/api/translations";
import $projects from "@/core/projects/store";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import styled from "@emotion/styled";

const Wrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  flex: 1,
}));

const Master = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [project, setProject] = React.useState("");
  const [language, setLanguage] = React.useState("");

  const onSetLanguage = (e, { props: { value } }) => {
    setLanguage(value);
  };

  const onSetProject = (e, { props: { value } }) => {
    setProject(value);
  };

  const getLanguages = () => {
    if (project) {
      const locales = $projects.getData
        .find(({ id }) => id === project)
        .locales.map(({ code }) => code);
      return Object.keys(languages)
        .filter((code) => locales.includes(code) === false)
        .map((code) => ({
          ...languages[code],
          key: languages[code].code,
          code,
        }));
    }
    return [];
  };

  const onCreateTranslate = async () => {
    setLoading(true);
    try {
      const { status } = await createTranslation({
        project,
        key: languages[language].code,
        code: language,
      });

      if (status === false) throw new Error();

      await $projects.fetchData();

      alert("перевод завершён!");
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <FormControl margin="normal" fullWidth>
        <InputLabel>Выберите проект</InputLabel>
        <Select value={project} label="Выберите проект" onChange={onSetProject}>
          {$projects.getData.map((item) => (
            <MenuItem key={`project-select-${item.id}`} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <InputLabel>Выберите язык</InputLabel>
        <Select value={language} label="Выберите язык" onChange={onSetLanguage}>
          {getLanguages().map((lang) => (
            <MenuItem key={`language-select-${lang.code}`} value={lang.code}>
              {lang.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <h1>{project}</h1>
      <h1>{language}</h1>
      {project && language && (
        <Button
          disabled={loading}
          onClick={onCreateTranslate}
          variant="contained"
        >
          Начать перевод
        </Button>
      )}
    </Wrapper>
  );
};

export default Master;
