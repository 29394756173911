import React from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import $translations from "@/core/translations/store";
import $user from "@/core/user/store";
import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import GoogleTranslate from "./Translate";
import $locales from "@/core/locales/store";

const Wrapper = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 25px;
  overflow-y: auto;
`;
const FieldsWrapper = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled("div")`
  margin: 30px 0 10px;
`;

const Editor = (props) => {
  const match = useParams();
  const active = $translations.getActiveMapped;
  const locales = React.useRef({});

  React.useEffect(() => {
    $translations.setActive(match.code);
  }, [match.code]);

  React.useEffect(() => {
    $locales.getData.forEach((locale) => {
      locales.current[locale.id] = locale.name;
    });
    console.log("locales.current", locales.current);
  }, []);

  if (!active) {
    return null;
  }

  const [ru] = active.translation;

  console.log("active", active);

  return (
    <Wrapper>
      <FieldsWrapper>
        <Typography gutterBottom variant="h5">
          {active.code}
        </Typography>
        {active.translation.map((t, i) => (
          <React.Fragment key={`editor-${t.code}`}>
            <InputWrapper>
              <TextField
                multiline
                fullWidth
                rows={2}
                value={t.value}
                autoFocus={i === 0 && !t.value}
                label={locales.current[t.code]}
                disabled={t.code === "ru" && $user.role !== "admin"}
                onChange={(e) =>
                  $translations.setTranslate(t.code, e.target.value)
                }
              />
            </InputWrapper>
            {i !== 0 && !t.value && ru.value && (
              <GoogleTranslate
                from={ru.value}
                to={t.code}
                onClick={(value) => $translations.setTranslate(t.code, value)}
              />
            )}
          </React.Fragment>
        ))}
      </FieldsWrapper>
    </Wrapper>
  );
};

export default observer(Editor);
