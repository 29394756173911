import { flow, types } from "mobx-state-tree";
import orderBy from "lodash/orderBy";
import $projects from "@/core/projects/store";
import { fetchData, saveData } from "@/core/api/translations";

const $translations = types
  .model("$translations", {
    data: types.array(
      types.model("$translations.item", {
        // код ru_RU, en_US
        code: types.string,
        // метка редактировался
        edited: types.maybe(types.boolean),
        // метка был создан
        created: types.maybe(types.boolean),
        // аккаумуляция всех слов используется для поиска
        query: types.maybe(types.string),
        // переводы
        translation: types.frozen({}),
      })
    ),
    active: types.maybe(types.string),
    // Метка изменялись переводы или нет
    changed: types.optional(types.boolean, false),
    search: types.maybe(types.string),
  })
  .actions((self) => ({
    fetchData: flow(function* () {
      if ($projects.getActive) {
        const data = yield fetchData({ project: $projects.getActive });
        self.setData(data);
      }
    }),
    getQuery(obj) {
      return Object.values(obj).join("|").toLowerCase();
    },
    setData(data) {
      const result = {};
      $projects.getLocales.forEach((locale, i) => {
        const values = data[i];
        Object.keys(values).forEach((key) => {
          if (!result[key]) result[key] = {};
          result[key][locale.id] = values[key];
        });
      });

      const array = [];
      Object.keys(result).forEach((code) => {
        array.push({
          code,
          translation: result[code],
          query: self.getQuery({ code, ...result[code] }),
        });
      });
      self.data = array;
    },
    setSearch(value) {
      self.search = value;
    },
    save: flow(function* save() {
      yield saveData(self.buildTranslate);
      self.changed = false;
    }),
    setActive(code) {
      self.active = code;
    },
    setTranslate(code, value) {
      const active = self.getActive;
      if (active) {
        active.translation = { ...active.translation, [code]: value };
        active.edited = true;
        self.changed = true;
        self.query = self.getQuery({ code, ...active.translation });
      }
    },
    push(key, codes = {}) {
      if (self.getById(key) === undefined) {
        const translation = {};
        $projects.getLocales.forEach(({ id }) => {
          translation[id] = codes[id] || "";
        });
        self.data.push({
          code: key,
          translation,
          created: true,
          query: self.getQuery({ key }),
        });
        return true;
      }
      return false;
    },
    destroy() {
      self.data = [];
      self.search = "";
      self.active = undefined;
      self.changed = false;
    },
  }))
  .views((self) => ({
    get getData() {
      return self.data;
    },
    get filteredData() {
      if (self.search) {
        return self.getData.filter((n) =>
          n.query.includes(self.search.toLowerCase())
        );
      }
      return self.getData;
    },
    get getSortedData() {
      const data = self.filteredData;
      return orderBy(data, ["created", "code"]).slice(0, 500);
    },
    get getActive() {
      return self.getData.find((d) => d.code === self.active);
    },
    getById(id) {
      return self.getData.find((d) => d.code === id);
    },
    get getActiveMapped() {
      const active = self.getActive;
      if (active) {
        const result = {
          code: active.code,
          translation: [],
        };
        $projects.getLocales.forEach(({ id }) => {
          result.translation.push({
            code: id,
            value: active.translation[id] || "",
          });
        });
        return result;
      }

      return undefined;
    },
    get buildTranslate() {
      const result = {};
      self.getData.forEach(({ code, translation }) => {
        $projects.getLocales.forEach(({ id }) => {
          if (result[id] === undefined) result[id] = {};
          result[id][code] = translation[id];
        });
      });
      return result;
    },
  }))
  .create();

export default $translations;
