import React from "react";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import $projects from "@/core/projects/store";
import $translations from "@/core/translations/store";
import $user from "@/core/user/store";
import CreateTranslate from "../CreateTranslate";
import UserPanel from "../UserPanel";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const goToTranslation = (value) => {
    navigate(`/project/${$projects.getActive.id}/${value}`);
  };

  const goBack = () => {
    navigate("/");
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setError(false);
    setOpen(false);
  };

  const onSave = ({ value, ru }) => {
    if (value) {
      goToTranslation(value);
      const res = $translations.push(value, { ru });
      if (res) {
        onClose();
      } else {
        setError(true);
      }
    }
  };

  return (
    <>
      <AppBar position="relative" color="secondary">
        <Toolbar>
          {location.pathname !== "/" && (
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              onClick={goBack}
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          {$projects.getActive ? (
            <Buttons onOpen={onOpen} />
          ) : (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Переводы
            </Typography>
          )}
          <UserPanel />
          {/*<ThemeSwitch />*/}
        </Toolbar>
      </AppBar>
      <CreateTranslate
        open={open}
        error={error}
        onClose={onClose}
        onSave={onSave}
      />
    </>
  );
};

const Buttons = observer((props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Box
        sx={{
          width: "247px",
          fontSize: "26px",
          fontWeight: "bold",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {$projects.getActive.name}
      </Box>
      <Button
        variant="contained"
        onClick={() => $translations.save()}
        sx={{ mr: 2 }}
        disabled={!$translations.changed}
        startIcon={<SaveIcon />}
      >
        Сохранить
      </Button>
      {$user.role === "admin" && (
        <Button
          variant="outlined"
          onClick={props.onOpen}
          startIcon={<AddIcon />}
        >
          Добавить
        </Button>
      )}
    </Box>
  );
});

export default observer(Header);
