import React from "react";
import { Link, useParams } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material";
import yellow from "@mui/material/colors/yellow";
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";

const PointWrapper = styled("div")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: 4,
}));

const Item = (props) => {
  const { code, text, selected, edited, created, isFull } = props;

  const secondaryAction = () => {
    if (edited || created || !isFull) {
      let color;
      if (created) color = green.A400;
      else if (!isFull) color = red.A400;
      else if (edited) color = yellow.A400;

      return <PointWrapper style={{ backgroundColor: color }} color={color} />;
    }
    return null;
  };

  return (
    <ListItem disablePadding secondaryAction={secondaryAction()}>
      <ListItemButton
        selected={selected}
        component={Link}
        to={encodeURIComponent(code)}
      >
        <ListItemText
          sx={{
            "& span": {
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            },
          }}
          primary={text || code}
        />
      </ListItemButton>
    </ListItem>
  );
};

function areEqual(prev, next) {
  return (
    prev.selected === next.selected &&
    prev.text === next.text &&
    prev.edited === next.edited &&
    prev.created === next.created &&
    prev.isFull === next.isFull
  );
}

export default React.memo(Item, areEqual);
