import axios from "axios";
import $snackbar from "@/core/ui/snackbar";
import $user from "@/core/user/store";

const instance = axios.create({
  baseURL: "/api/v1",
});

instance.interceptors.response.use(
  function (response) {
    if (response.config.method === "put") {
      $snackbar.open("Изменения сохранены");
    }
    if (response.data.status === false) {
      throw response.data;
    }
    return response.data;
  },
  function (error) {
    console.log("error", error);
    if (
      error.code === "ECONNABORTED" ||
      (error.response && error.response.status === 502)
    ) {
      console.info("failed load");
    }
    if (error.response.code === 501) {
      $user.logout();
    }
    $snackbar.open("Произошла ошибка", "error");
    return Promise.reject(error);
  }
);

export function GET(url, query, config = {}) {
  return instance.get(url, { params: query, ...config });
}

export function POST(url, data, config) {
  return instance.post(url, data, config);
}

export function PUT(url, data) {
  return instance.put(url, data);
}

export function DEL(url) {
  return instance.delete(url);
}
