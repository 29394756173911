import React from "react";
import GlobalStyles from "@mui/material/GlobalStyles";
import { grey } from "@mui/material/colors";

const Global = ({ mode }) => {
  return (
    <GlobalStyles
      styles={{
        html: {
          height: "100%",
          overflow: "hidden",
        },
        body: {
          height: "100%",
          overflow: "hidden",
        },
        "#root": {
          overflow: "hidden",
          height: "100vh",
          maxHeight: "100%",
          width: "100%",
          display: "flex",
        },
        "@media (hover: hover)": {
          "::-webkit-scrollbar": {
            width: 5,
            height: 5,
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: mode === "dark" ? grey[600] : grey[200],
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: mode === "dark" ? grey[300] : grey[500],
            borderRadius: 2,
          },
        },
      }}
    />
  );
};

export default Global;
