import {types} from "mobx-state-tree"

const $snackbar = types
  .model({
    text: types.maybe(types.string),
    type: types.maybe(types.string),
    time: types.maybe(types.number),
  })
  .actions(self => ({
    open(text, type = 'info') {
      self.text = text;
      self.type = type;
      self.time = new Date().getTime();
    },
  }))
  .create()

export default $snackbar
