import * as React from "react";
import { translate } from "@/core/api/translate";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const CreateTranslate = (props) => {
  const { open, onClose, error } = props;
  const [value, setValue] = React.useState("");
  const [ru, setRU] = React.useState("");

  const onSave = () => {
    props.onSave({ value, ru });
  };

  const onConvert = async (e) => {
    try {
      setRU(e.target.value);
      const { data } = await translate({
        text: e.target.value,
        from: "ru",
        to: "en",
        lite: true,
      });
      const { text } = data.translations[0];
      const newValue = text
        .replace(/\s/g, "_")
        .replace(/[^A-z0-9_]/g, "")
        .toUpperCase();
      setValue(newValue);
    } catch (e) {
      console.warn(e);
    }
  };

  React.useEffect(() => {
    if (!open) setValue("");
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Создание перевода</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Введите ключ перевода на английском языке
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="translate-name"
          label="На русском"
          fullWidth
          onBlur={onConvert}
        />
        <TextField
          error={error}
          helperText={error && "Уже существует"}
          margin="dense"
          id="translate-name"
          label="Ключ"
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отмена</Button>
        <Button onClick={onSave} variant="contained">
          Создать
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTranslate;
