import React from "react";
import { observer } from "mobx-react";
import $snackbar from "@/core/ui/snackbar";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const SnackBar = () => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if ($snackbar.time) setOpen(true);
  }, [$snackbar.time]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={$snackbar.type}
      >
        {$snackbar.text}
      </Alert>
    </Snackbar>
  );
};

export default observer(SnackBar);
