import {GET, POST} from "./index";

export function auth(data) {
  return POST("/auth", data)
}

export function profile() {
  return GET("/profile")
}

export function logout() {
  return GET("/logout")
}
