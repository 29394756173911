import React from "react";
import { observer } from "mobx-react";
import { Routes, Route, useParams } from "react-router-dom";
import $projects from "@/core/projects/store";
import $translations from "@/core/translations/store";
import { styled } from "@mui/material";
import Editor from "./Editor";
import LeftMenu from "../LeftMenu";

const Wrapper = styled("div")`
  display: flex;
  overflow: hidden;
  flex: 1;
`;

const TranslateEditor = (props) => {
  const match = useParams();
  console.log("TranslateEditor", match);

  React.useEffect(() => {
    $projects.setActive(match.projectId);
  }, [match.projectId]);

  if ($translations.getData.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <LeftMenu />
      <Routes>
        <Route path="/:code" element={<Editor />} />
      </Routes>
    </Wrapper>
  );
};

export default observer(TranslateEditor);
