import * as React from "react";
import $translations from "@/core/translations/store";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import Item from "./Item";

const Wrapper = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${theme.palette.divider};
`
);
const SearchWrapper = styled("div")`
  padding: 10px;
`;
const ListWrapper = styled(List)`
  width: 300px;
  overflow-y: auto;
  flex: 1;
`;

const checkIsFull = (translation) => {
  for (let key in translation) {
    if (translation[key] === "") return false;
  }
  return true;
};

const LeftMenu = () => {
  const match = useParams();

  return (
    <Wrapper>
      <SearchWrapper>
        <TextField
          placeholder="Поиск"
          size="small"
          fullWidth
          onChange={(e) => $translations.setSearch(e.target.value)}
        />
      </SearchWrapper>
      <Divider />
      <ListWrapper dense>
        {$translations.getSortedData.map((item) => (
          <React.Fragment key={`list-${item.code}`}>
            <Item
              code={item.code}
              edited={item.edited}
              created={item.created}
              isFull={checkIsFull(item.translation)}
              text={item.translation.ru}
              selected={item.code === match["*"]}
            />
            <Divider light />
          </React.Fragment>
        ))}
      </ListWrapper>
    </Wrapper>
  );
};

export default observer(LeftMenu);
