import { flow, types } from "mobx-state-tree";
import { auth, logout, profile } from "@/core/api/user";

const $user = types
  .model("$user", {
    isAuth: types.boolean,
    role: types.string,
    login: types.string,
    username: types.string,
    permissions: types.model("$user.permissions", {
      locales: types.maybe(types.array(types.string)),
      projects: types.maybe(types.array(types.string)),
    }),
  })
  .actions((self) => ({
    setData(data) {
      Object.keys(data).forEach((key) => {
        self[key] = data[key];
      });
    },
    auth: flow(function* ({ login, password }) {
      const { data } = yield auth({ login, password });
      self.isAuth = true;
      self.setData(data);
    }),
    profile: flow(function* () {
      const { data } = yield profile();
      self.isAuth = true;
      self.setData(data);
    }),
    logout: flow(function* () {
      yield logout();
      self.isAuth = false;
      self.role = "";
      self.login = "";
      self.username = "";
      self.permissions = [];
    }),
  }))
  .views((self) => ({}))
  .create({
    isAuth: false,
    role: "",
    login: "",
    username: "",
    permissions: {},
  });

export default $user;
