import {types} from "mobx-state-tree"
import persist from "mst-persist";

const $theme = types
  .model({
    mode: types.string,
  })
  .actions(self => ({
    switch() {
      if (self.mode === "dark") self.mode = "light"
      else self.mode = "dark"
    },
  }))
  .create({
    mode: "dark"
  });

persist('@theme', $theme, {
  storage: localStorage,
  jsonify: true, // set to true if using AsyncStorage
  whitelist: [
    'mode',
  ]
})

export default $theme
