import { POST, PUT } from "./index";
import $projects from "@/core/projects/store";

export function createTranslation(body) {
  return POST("/create-translation", body);
}

export function fetchData(body) {
  return POST("/get-translations", body);
}

export function saveData(body) {
  return PUT(`/set-translations/${$projects.getActive?.id}`, body);
}
