import React from "react";
import { fetchAllLocales } from "@/core/api/locales";
import $locales from "@/core/locales/store";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import $projects from "@/core/projects/store";
import { CircularProgress, ListItem, ListItemText } from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import CheckIcon from "@mui/icons-material/Check";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { createTranslation } from "@/core/api/translations";

const Form = () => {
  const [value, setValue] = React.useState({});
  const [locales, setLocales] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [statuses, setStatuses] = React.useState({});

  const onChange = (e, value) => {
    setValue(value);
  };

  const setStatus = (project, status) => {
    setStatuses((statuses) => ({
      ...statuses,
      [project]: status,
    }));
  };

  const getStatuses = () => {
    const res = {};
    $projects.getData.forEach((project) => {
      res[project.id] = "pending";
    });
    return res;
  };

  const onCreate = async () => {
    setLoading(true);
    await $locales.save(value);

    const statuses = getStatuses();
    setStatuses(statuses);
    for (let projectId in statuses) {
      try {
        setStatus(projectId, "loading");
        await createTranslation({
          projectId,
          targetLang: value.id,
        });
        setStatus(projectId, "complete");
      } catch (e) {
        setStatus(projectId, "error");
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      const { data } = await fetchAllLocales();
      setLocales(data);
    })();
  }, []);

  return (
    <Box sx={{ flex: 1, p: 2 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h6" gutterBottom>
          Создание переводов на новом языке
        </Typography>
        <AutocompleteLocales options={locales} onChange={onChange} />
        <Button
          disabled={!value.id}
          sx={{ mt: 2 }}
          size="large"
          variant="contained"
          onClick={onCreate}
        >
          Создать
        </Button>
        <ProjectStatuses statuses={statuses} />
      </Paper>
    </Box>
  );
};

function ProjectStatuses({ statuses }) {
  const renderItem = (item, index) => {
    return (
      <React.Fragment key={`project-${item.id}`}>
        {index !== 0 && <Divider component="li" />}
        <ListItem>
          <ListItemText primary={item.name} secondary={item.id} />
          {statuses[item.id] === "loading" && <CircularProgress />}
          {statuses[item.id] === "complete" && <CheckIcon color="success" />}
          {statuses[item.id] === "error" && <WarningAmberIcon color="error" />}
        </ListItem>
      </React.Fragment>
    );
  };

  if (Object.keys(statuses).length === 0) return null;

  return (
    <Box sx={{ pt: 4 }}>
      <Typography variant="h6" gutterBottom>
        Статус создания переводов
      </Typography>
      <List sx={{ width: "100%" }}>{$projects.getData.map(renderItem)}</List>
    </Box>
  );
}

function AutocompleteLocales({ options, onChange }) {
  return (
    <Autocomplete
      disablePortal
      id="locales-autocomplete"
      getOptionLabel={(option) => option.name}
      options={options}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} label="Выберите язык" />}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
            alt=""
          />
          {option.id.toUpperCase()} - {option.name}
        </Box>
      )}
    />
  );
}

export default Form;
