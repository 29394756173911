import React from "react";
import $user from "@/core/user/store";
import { observer } from "mobx-react";
import { styled } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";

const ButtonWrapper = styled(ButtonBase)(({ theme }) => ({
  textAlign: "left",
  padding: theme.spacing(1),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(-1),
  borderRadius: theme.shape.borderRadius,
  maxWidth: 300,
  "& > div:first-of-type": {
    marginRight: theme.spacing(1),
  },
  "& > div:nth-of-type(2)": {
    flex: 1,
    overflow: "hidden",
    "& > div": {
      fontWeight: "bold",
    },
  },
}));

const UserPanel = (props) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleExit = async () => {
    await $user.logout();
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <ButtonWrapper edge="end" onClick={handleOpenUserMenu}>
        <Avatar size={5}>
          <PersonIcon />
        </Avatar>
        <div>
          <Typography noWrap variant="body2" component="div">
            {$user.username}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {$user.role}
          </Typography>
        </div>
      </ButtonWrapper>
      <Menu
        sx={{ mt: "55px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleExit}>
          <Typography>Выход</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default observer(UserPanel);
